import $http from "@/plugin/axios.js";
export default {
  state: {
    warehouses: [],
  },
  getters: {
    warehouses: (state) => state.warehouses,
  },
  actions: {
    async getWarehouses({ commit }, payload) {
      const { data } = await $http.post("/website/GetWarehouses", {
        ...payload,
        Language: localStorage.getItem("locale")
          ? localStorage.getItem("locale").toUpperCase()
          : "EN",
      });
      commit("SET_WAREHOUSES", data.ResponseData);
    },
  },
  mutations: {
    SET_WAREHOUSES(state, data) {
      state.warehouses = data;
    },
  },
};
