import $http from "@/plugin/axios.js";
export default {
  state: {
    sliders: [],
    abouts: [],
    services: [],
    categories: [],
    homeData: {},
    tips: [],
    FAQ: [],
  },
  getters: {
    sliders: (state) => state.sliders,
    abouts: (state) => state.abouts,
    homeData: (state) => state.homeData,
    tips: (state) => state.tips,
    FAQ: (state) => state.FAQ,
  },
  actions: {
    async getSliders({ commit }, payload) {
      const { data } = await $http.post("/website/GetSliderInfos", {
        ...payload,
        Language: localStorage.getItem("locale")
          ? localStorage.getItem("locale").toUpperCase()
          : "EN",
      });
      commit("SET_SLIDERS", data.ResponseData);
    },
    async getAbouts({ commit }, payload) {
      const { data } = await $http.post("/website/GetAboutUsInfos", {
        ...payload,
        Language: localStorage.getItem("locale")
          ? localStorage.getItem("locale").toUpperCase()
          : "en",
      });
      commit("SET_ABOUTS", data.ResponseData);
    },
    async getHomeData({ commit }, payload) {
      const { data } = await $http.post("/website/gethomepage", {
        ...payload,
        Language: localStorage.getItem("locale")
          ? localStorage.getItem("locale").toUpperCase()
          : "en",
      });
      commit("SET_HOME_DATA", data.ResponseData);
    },
    async getFAQS({ commit }, payload) {
      const { data } = await $http.post("/website/getfaqs", {
        ...payload,
        PageNumber: 1,
        PageSize: 100,
        Language: localStorage.getItem("locale")
          ? localStorage.getItem("locale").toUpperCase()
          : "en",
      });
      commit("SET_FAQS", data.ResponseData.Data);
    },
    async AddContact(_, payload) {
      return await $http.post("/website/AddContact", payload);
    },
  },
  mutations: {
    SET_SLIDERS(state, data) {
      state.sliders = data;
    },
    SET_ABOUTS(state, data) {
      state.abouts = data;
    },
    SET_HOME_DATA(state, data) {
      state.homeData = data;
    },
    SET_TIPS(state, data) {
      state.tips = data;
    },
    SET_FAQS(state, data) {
      state.FAQ = data;
    },
  },
};
