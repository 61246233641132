import Vue from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import { BootstrapVue } from "bootstrap-vue";
import VueMeta from "vue-meta";
/* add fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";
import Splide from "@splidejs/vue-splide";
import OneSignalVue from "onesignal-vue";

Vue.use(OneSignalVue);

/* import font awesome icon component */
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";
import { fab } from "@fortawesome/free-brands-svg-icons";

// Import Bootstrap and BootstrapVue CSS files (order is important)
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "@/assets/sass/main.scss";

import VueSweetalert2 from "vue-sweetalert2";

// If you don't need the styles, do not connect
import "sweetalert2/dist/sweetalert2.min.css";
import "@splidejs/splide/dist/css/splide.min.css";

import i18n from "./i18n";
Vue.use(VueSweetalert2);
Vue.use(Splide);
Vue.use(BootstrapVue);
Vue.use(VueMeta, {
  refreshOnceOnNavigation: true,
  title: "9ar Store",
  link: [{ rel: "icon", href: "/favicon.ico" }],
  meta: [
    { charset: "utf-8" }, // Example: charset meta tag
    { name: "viewport", content: "width=device-width, initial-scale=1.0" }, // Example: viewport meta tag
    {
      name: "description",
      content:
        "في 9ar، نحن لسنا مجرد خدمة - نحن مجموعة من الأشخاص الذين واجهوا تحديات التسوق من بلدان أخرى. لقد كنا هناك، وفعلنا ذلك، ولهذا السبب نحن هنا لنجعل الأمر أسهل بالنسبة لك. بالإضافة إلى ذلك، نحن نؤمن بتزويدك بخدمة عملاء مخصصة لجعل تجربتك أفضل.",
    }, // Example: description meta tag
    { property: "og:title", content: "9ar Store" }, // Example: Open Graph title meta tag
    {
      property: "og:description",
      content:
        "في 9ar، نحن لسنا مجرد خدمة - نحن مجموعة من الأشخاص الذين واجهوا تحديات التسوق من بلدان أخرى. لقد كنا هناك، وفعلنا ذلك، ولهذا السبب نحن هنا لنجعل الأمر أسهل بالنسبة لك. بالإضافة إلى ذلك، نحن نؤمن بتزويدك بخدمة عملاء مخصصة لجعل تجربتك أفضل.",
    },
    { name: "theme-color", content: "#f7652b" },
  ],
});
library.add(fas, far, fab);

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

let mixin = {
  data() {
    return {
      baseUrl: "https://api.9arstore.com/Image/",
    };
  },
  computed: {},
  methods: {},
};

Vue.mixin(mixin);

new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
  beforeMount() {
    this.$OneSignal.init({ appId: "16700787-0997-4b12-be44-b79e461f7fc6" });
  },
}).$mount("#app");
