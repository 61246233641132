import $http from "@/plugin/axios.js";
export default {
  state: {
    cities: [],
  },
  getters: {
    cities: (state) => state.cities,
  },
  actions: {
    async getCities({ commit }) {
      const response = await $http.post(
        "https://api.9arstore.com/api/dashboard/DictDropdown",
        {
          Key: "City",
        }
      );
      commit("SET_CITIES", response.data.ResponseData);
    },
  },
  mutations: {
    SET_CITIES(state, data) {
      state.cities = data;
    },
  },
};
