import $http from "@/plugin/axios.js";
export default {
  state: {
    tickets: [],
    ticketReplay: [],
  },
  getters: {
    tickets: (state) => state.tickets,
    ticketReplay: (state) => state.ticketReplay,
  },
  actions: {
    async getTickets({ commit }, payload) {
      const response = await $http.post("/website/gettickets", payload);
      commit("SET_TICKETS", response.data.ResponseData);
    },
    async sortTicket(_, payload) {
      return await $http.post("/website/addticket", payload);
    },
    async getTicketReplies({ commit }, payload) {
      const response = await $http.post("/website/getticketreplies", payload);
      commit("SET_TICKET_REPLIES", response.data.ResponseData);
    },
    async sortTicketReplies(_, payload) {
      return await $http.post("/website/addticketreply", payload);
    },
  },
  mutations: {
    SET_TICKETS(state, data) {
      state.tickets = data;
    },
    SET_TICKET_REPLIES(state, data) {
      state.ticketReplay = data;
    },
  },
};
