import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

// {
//         path: "/",
//         redirect: `/home`,
//       },

const routes = [
  // {
  //   path: "*",
  //   name: "404",
  //   component: () => import("@/views/PageNotFound.vue"),
  // },
  {
    path: "/auth",
    name: "AuthLayout",
    component: () => import("@/layout/authLayout.vue"),
    children: [],
  },
  {
    path: "/",
    name: "DefaultLayout",
    component: () => import("@/layout/defaultLayout.vue"),
    children: [
      {
        path: "",
        name: "home",
        component: () => import("@/views/HomeView.vue"),
      },
      {
        path: "/about",
        name: "about",
        component: () => import("@/views/AboutView.vue"),
      },
      {
        path: "/contact-us",
        name: "contact",
        component: () => import("@/views/ContactUsView.vue"),
      },
      {
        path: "/faq",
        name: "faq",
        component: () => import("@/views/FAQView.vue"),
      },
      {
        path: "/terms",
        name: "terms",
        component: () => import("@/views/TermsView.vue"),
      },
      {
        path: "/privacy-policy",
        name: "privacy-policy",
        component: () => import("@/views/PrivecyView.vue"),
      },
      {
        path: "/prices",
        name: "prices",
        component: () => import("@/views/PricesView.vue"),
      },
      {
        path: "/login",
        name: "login",
        component: () => import("@/views/Auth/LoginView.vue"),
        meta: {
          permission: [],
        },
      },
      {
        path: "/register",
        name: "register",
        component: () => import("@/views/Auth/RegisterView.vue"),
        meta: {
          permission: [],
        },
      },
      {
        path: "/otp/:phone?/:to?",
        name: "OTP",
        component: () => import("@/views/Auth/OTOView.vue"),
        meta: {
          permission: [],
        },
      },
      {
        path: "/change-password/:phone?",
        name: "ChangePassword",
        component: () => import("@/views/Auth/changePassword.vue"),
        meta: {
          permission: [],
        },
      },
      // authenticated
      // end of authenticated
    ],
  },
  {
    path: "/",
    name: "dashboardLayout",
    component: () => import("@/layout/dashboardLayout.vue"),
    children: [
      {
        path: "/received-packages",
        name: "receivedPackages",
        component: () => import("@/views/ReceivedPackagesView.vue"),
      },
      {
        path: "/shipped-packages",
        name: "shippedPackages",
        component: () => import("@/views/ShippedPackagesView.vue"),
      },
      {
        path: "/cart",
        name: "cart",
        component: () => import("@/views/CartView.vue"),
      },
      {
        path: "/profile",
        name: "profile",
        component: () => import("@/views/ProfileView.vue"),
      },
      {
        path: "/stores",
        name: "stores",
        component: () => import("@/views/BrandsView.vue"),
      },
      {
        path: "/my-address",
        name: "myAddress",
        component: () => import("@/views/MyAddressView.vue"),
      },
      {
        path: "/warehouse-address",
        name: "warehouse",
        component: () => import("@/views/WarehouseView.vue"),
      },
      {
        path: "/notifications",
        name: "notifications",
        component: () => import("@/views/NotificationsView.vue"),
      },
      {
        path: "/checkout",
        name: "checkout",
        component: () => import("@/views/CheckoutView.vue"),
      },
      {
        path: "/message",
        name: "message",
        component: () => import("@/views/MessageView.vue"),
      },
      {
        path: "/orders",
        name: "orders",
        component: () => import("@/views/OrdersView.vue"),
      },
      {
        path: "/order/:id",
        name: "order",
        component: () => import("@/views/OrderView.vue"),
      },
      {
        path: "/buy-for-me",
        name: "buyForMe",
        component: () => import("@/views/BuyForMeView.vue"),
      },
      {
        path: "/product/:name",
        name: "productDetails",
        component: () => import("@/views/ProductDetailsView.vue"),
      },
      {
        path: "/help-center",
        name: "helpCenter",
        component: () => import("@/views/HelpView.vue"),
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
