import $http from "@/plugin/axios.js";
export default {
  state: {
    sittings: [],
    TermsAndConditions: {
      VALUE: "",
    },
    PrivacyPolicy: {
      VALUE: "",
    },
    PhoneNumber: {
      VALUE: "",
    },
    Logo: {},
  },
  getters: {
    sittings: (state) => state.sittings,
  },
  actions: {
    async getSittings({ commit }) {
      const response = await $http.post("website/GetStoreSitting", {
        Key: "",
        Language: localStorage.getItem("locale")
          ? localStorage.getItem("locale").toUpperCase()
          : "EN",
      });
      commit("SET_SITTINGS", response.data.ResponseData);
    },
    async getSittingData({ commit }, payload) {
      const response = await $http.post("website/GetStoreSitting", {
        Key: payload.search,
        Language: "AR",
      });
      commit("SET_DATA", {
        response: response.data.ResponseData[0] || {},
        key: payload.key,
      });
    },
  },
  mutations: {
    SET_SITTINGS(state, data) {
      state.sittings = data;
    },
    SET_DATA(state, data) {
      state[data.key] = data.response;
    },
  },
};
