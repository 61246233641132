import axios from "axios";
import store from "@/store";
import Vue from "vue";
// export const baseUrl = "http://sarproject-001-site1.etempurl.com/api";

const requests = [
  "/website/AddContact",
  "/website/addrating",
  "/website/updateprofile",
  "/website/updateprofile",
  "/website/AddRequest",
  "/website/addticket",
  "/website/adduseraddress",
  "/website/edituseraddress",
];

// export const baseUrl = "http://sarproject-001-site1.etempurl.com/api";
export const baseUrl = "https://api.9arstore.com/api";

axios.interceptors.request.use(
  (config) => {
    config.baseURL = baseUrl;
    store.state.loader = true;
    config.headers = {
      Authorization: `Bearer ${
        localStorage.getItem("sar-web-token") !== null
          ? JSON.parse(localStorage.getItem("sar-web-token"))
          : false
      }`,
      proxy: false,
      Accept: "application/json",
      "Access-Control-Allow-Origin": "*",
    };
    return config;
  },

  (error) => {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  (response) => {
    store.state.loader = false;
    if (requests.includes(response.config.url)) {
      Vue.prototype.$swal({
        title: "Process Done",
        icon: "success",
      });
    }
    return response;
  },
  (error) => {
    store.state.loader = false;
    if (error?.response?.status === 401) {
      store.dispatch("logout");
      Vue.prototype.$swal({
        title: "User session expired",
        text: "Please login now!",
        icon: "error",
      });
    } else {
      Vue.prototype.$swal({
        title: error.response.data.StatusDescription || "Error",
        text: "An error occurred. Please try again later.",
        icon: "error",
      });
    }
    return Promise.reject(error);
  }
);

export default axios;
