import $http from "@/plugin/axios.js";
export default {
  state: {
    buyForMeList: [],
  },
  getters: {
    buyForMeList: (state) => state.buyForMeList,
  },
  actions: {
    async getBuyFormMe({ commit }, payload) {
      const response = await $http.post("/website/GetRequests", {
        ...payload,
        Language: localStorage.getItem("locale")
          ? localStorage.getItem("locale").toUpperCase()
          : "EN",
      });
      commit("SET_BUY_FOR_ME", response.data.ResponseData);
    },
    async addBuyFormMe(_, payload) {
      return await $http.post("/website/AddRequest", payload);
    },
  },
  mutations: {
    SET_BUY_FOR_ME(state, data) {
      state.buyForMeList = data;
    },
  },
};
