import $http from "@/plugin/axios.js";
export default {
  state: {
    products: [],
  },
  getters: {
    products: (state) => state.products,
  },
  actions: {
    async getProducts({ commit }, payload) {
      const { data } = await $http.post("/website/GetProducts", {
        ...payload,
        Language: localStorage.getItem("locale")
          ? localStorage.getItem("locale").toUpperCase()
          : "EN",
      });
      commit("SET_PRODUCTS", data.ResponseData);
    },
    async changeProductStatus(_, payload) {
      return await $http.post("/website/ChangeStatusRequest", payload);
    },
  },
  mutations: {
    SET_PRODUCTS(state, data) {
      state.products = data;
    },
  },
};
