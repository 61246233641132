import $http from "@/plugin/axios.js";
export default {
  state: {
    notifications: [],
    notificationCount: 0,
  },
  getters: {
    notifications: (state) => state.notifications,
    notificationCount: (state) => state.notificationCount,
  },
  actions: {
    async getNotifications({ commit }) {
      const response = await $http.post("/website/GetNotifications", {
        Language: localStorage.getItem("locale")
          ? localStorage.getItem("locale").toUpperCase()
          : "EN",
        PageNumber: 1,
        PageSize: 20,
      });
      commit("SET_NOTIFICATIONS", response);
    },
    async getNotificationCount({ commit }) {
      const response = await $http.post(
        "/website/GetUnreadNotificationsCount",
        {
          Language: "EN",
        }
      );
      commit("SET_NOTIFICATION_COUNT", response);
    },
    async markAsRead(_, ids) {
      await $http.post("/website/BulkRead", {
        Ids: ids,
      });
    },
  },
  mutations: {
    SET_NOTIFICATIONS(state, data) {
      state.notifications = data.data.ResponseData.Data;
    },
    SET_NOTIFICATION_COUNT(state, data) {
      state.notificationCount = data.data.ResponseData;
    },
  },
};
