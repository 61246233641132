import $http from "@/plugin/axios.js";
export default {
  state: {
    promoCode: {},
  },
  getters: {
    promoCode: (state) => state.promoCode,
  },
  actions: {
    async checkPromoCode({ commit }, payload) {
      const response = await $http.post("website/validatepromocode", payload);
      commit("SET_PROMOCODE", response.data.ResponseData);
      return response?.data?.ResponseData;
    },
  },
  mutations: {
    SET_PROMOCODE(state, data) {
      state.promoCode = data;
    },
  },
};
