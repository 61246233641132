import $http from "@/plugin/axios.js";
export default {
  state: {
    orders: [],
    order: {},
    orderStatusList: [],
  },
  getters: {
    orders: (state) => state.orders,
    order: (state) => state.order,
    orderStatus: (state) => state.orderStatusList,
  },
  actions: {
    async getOrders({ commit }, payload) {
      const response = await $http.post("/website/GetOrders", {
        ...payload,
        Language: localStorage.getItem("locale")
          ? localStorage.getItem("locale").toUpperCase()
          : "EN",
      });
      commit("SET_ORDERS", response.data);
    },
    async getOrder({ commit }, id) {
      const response = await $http.post("/website/GetOrder", {
        Id: id,
        Language: "EN",
      });
      commit("SET_ORDER", response.data.ResponseData);
    },
    async getOrderStatuses({ commit }) {
      const response = await $http.post(
        "https://api.9arstore.com/api/dashboard/DictDropdown",
        {
          Key: "ORDER STATUS",
        }
      );
      commit("SET_ORDER_STATUS", response.data.ResponseData);
    },
    async sortOrders(_, payload) {
      return await $http.post("/website/AddOrder", payload);
    },
  },
  mutations: {
    SET_ORDERS(state, data) {
      state.orders = data;
    },
    SET_ORDER(state, data) {
      state.order = data;
    },
    SET_ORDER_STATUS(state, data) {
      state.orderStatusList = data;
    },
  },
};
