import $http from "@/plugin/axios.js";
import router from "@/router";
export default {
  state: {
    isAuth: localStorage.getItem("sar-web-token") !== null ? true : false,
    userData: {},
    profile: {},
  },
  getters: {
    isAuth: (state) => state.isAuth,
    profile: (state) => state.profile,
  },
  actions: {
    async signin({ commit }, payload) {
      const { data } = await $http.post("/website/login", payload);
      commit("SET_LOGIN_TOKEN", data);
      commit("AUTHENTICATE_USER", true);
    },
    async regester(_, payload) {
      await $http.post("/website/signup", payload);
    },
    async getProfile({ commit }) {
      const { data } = await $http.post("/website/getprofile");
      commit("SET_PROFILE", data);
    },
    async updateProfile(_, payload) {
      return await $http.post("/website/updateprofile", payload);
    },
    async changePassword(_, payload) {
      return await $http.post("/website/changepassword", payload);
    },
    async resendOtp(_, payload) {
      return await $http.post("/website/ResendOtp", payload);
    },
    async verifyOtp({ commit }, payload) {
      const { data } = await $http.post("/website/VerifyOtp", payload);
      commit("SET_LOGIN_TOKEN", data);
      commit("AUTHENTICATE_USER", true);
    },
    async resetPassword(_, payload) {
      return await $http.post("/website/resetpassword", payload);
    },
    async logout({ commit }) {
      commit("LOGOUT");
    },
  },
  mutations: {
    AUTHENTICATE_USER(state, data) {
      state.isAuth = data;
    },
    LOGOUT(state) {
      state.isAuth = false;
      localStorage.removeItem("sar-web-token");
      router.push({ name: "home" });
    },
    SET_PROFILE(state, data) {
      state.profile = data;
    },
    SET_LOGIN_TOKEN(state, data) {
      localStorage.setItem("sar-web-token", JSON.stringify(data.ResponseData));
    },
  },
};
