import $http from "@/plugin/axios.js";
export default {
  state: {},
  getters: {},
  actions: {
    async addRating(_, payload) {
      return await $http.post("/website/addrating", payload);
    },
  },
  mutations: {},
};
