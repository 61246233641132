import $http from "@/plugin/axios.js";
export default {
  state: {
    address: {
      Data: [],
    },
  },
  getters: {
    address: (state) => state.address,
  },
  actions: {
    async getAddress({ commit }, payload) {
      const { data } = await $http.post("/website/getuseraddresses", {
        ...payload,
        Language: localStorage.getItem("locale")
          ? localStorage.getItem("locale").toUpperCase()
          : "EN",
      });
      commit("SET_ADDRESS", data.ResponseData);
    },
    async addAddAddress(_, payload) {
      return await $http.post("/website/adduseraddress", payload);
    },
    async updateAddress(_, payload) {
      return await $http.post("/website/edituseraddress", payload);
    },
    async removeAddress(_, id) {
      return await $http.post("/website/deleteuseraddress", {
        Id: id,
      });
    },
  },
  mutations: {
    SET_ADDRESS(state, data) {
      state.address = data;
    },
  },
};
