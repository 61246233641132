import Vue from "vue";
import Vuex from "vuex";
import Auth from "./modules/auth";
// import Base from "./modules/base";
import homeData from "./modules/homeData";
import orders from "./modules/orders";
import buyForME from "./modules/BuyForMe";
import rating from "./modules/rating";
import cities from "./modules/cities";
import tickets from "./modules/tickets";
import sittings from "./modules/siteSitting";
import promoCode from "./modules/promoCode";
import products from "./modules/products";
import address from "./modules/address";
import warehouses from "@/store/modules/warehouses";
import notifications from "@/store/modules/notifications";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    loader: false,
    active_sidebar: true,
  },
  getters: {},
  mutations: {
    TOGGLE_SIDEBAR(state) {
      state.active_sidebar = !state.active_sidebar;
    },
  },
  actions: {},
  modules: {
    Auth,
    // Base,
    homeData,
    orders,
    buyForME,
    rating,
    cities,
    tickets,
    sittings,
    promoCode,
    products,
    address,
    warehouses,
    notifications,
  },
});
