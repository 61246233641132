<template>
  <div id="app" :class="$i18n.locale === 'en' ? 'ltr' : 'rtl'">
    <div
      v-if="$store.state.loader"
      class="loader position-fixed top-0 left-0 w-100 h-100 d-flex align-items-center justify-content-center"
    >
      <div class="spin">
        <img width="50" src="@/assets/images/loader.gif" alt="" />
      </div>
    </div>
    <router-view />
  </div>
</template>
<script>
export default {
  // beforeCreate() {
  //   this.$OneSignal.User.PushSubscription.optIn();
  // },
  mounted() {
    // this.$OneSignal
    //   .init({ appId: "16700787-0997-4b12-be44-b79e461f7fc6" })
    //   .then(() => {
    //     // do other stuff
    //   });
    if (window.innerWidth <= 768) {
      this.$store.commit("TOGGLE_SIDEBAR");
    }
  },
};
</script>
<style>
.loader {
  background: rgba(255, 255, 255, 0.25);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.7px);
  -webkit-backdrop-filter: blur(6.7px);
  border: 1px solid rgba(255, 255, 255, 0.3);
  z-index: 9999;
}
.loader .spin {
  background-color: white;
  padding: 10px;
  border-radius: 50%;
}
</style>
